import { mapGetters } from 'vuex'
import commonConfig from 'config'
import AppStore from 'mixins/app-store'
import InsiderMixin from 'mixins/insider-mixin'
import bestPriceEnum from 'mixins/bestPriceEnum'
import Intercom from 'mixins/intercom'
import FaqEnum from '../../../core/domain/enums/shared/FaqEnum'
import { AuthModalMethods } from '../../Customer/Auth/Auth'

export default {
  name: 'sideNav',

  mixins: [AppStore, InsiderMixin, Intercom],

  data() {
    return {
      nationalityIconClass: null,
      commonConfig: commonConfig,
      helpUrl: FaqEnum.HELP_URL
    }
  },

  watch: {
    config(state) {
      if (process.server) return

      if (state.hasOwnProperty('menu') && state.menu === true) {
        this.linkNavbar()
      } else {
        this.unlinkNavbar()
      }
    }
  },

  computed: {
    ...mapGetters({
      currency: 'getCurrency',
      validLogin: 'user/getValidLogin',
      config: 'header/getConfig',
      bookingsCount: 'user/getBookingsCount',
      storeLocale: 'getLocale'
    }),

    locale() {
      return this.storeLocale.toUpperCase()
    },

    /**
     * @desc returns the message for SideNav
     * @returns {string}
     */
    helloMessage: function () {
      if (this.$store.getters['user/getValidLogin']) {
        return this.$t('common.hello') + ', ' + this.$store.getters['user/getUserName']
      } else {
        return this.$t('common.hello')
      }
    }
  },

  mounted() {
    // Hides sidenav's overlay when the back button pressed
    window.onpopstate = function (event) {
      $('.open-side-nav').sideNav('hide')
    }

    setTimeout(() => {
      this.linkNavbar()
    }, 500)
  },

  methods: {
    ...AuthModalMethods,
    linkNavbar() {
      $('document').ready(() => {
        $('.open-side-nav').sideNav({
          edge: 'right',
          closeOnClick: true
        })
        if (this.shouldBeOpened()) {
          $('.open-side-nav').sideNav('show')
        }
      })
    },

    unlinkNavbar() {
      $('document').ready(() => {
        $('.open-side-nav').sideNav('destroy')
      })
    },

    shouldBeOpened() {
      const urlParams = new URLSearchParams(window.location.search)
      const openedSidenav = urlParams.get('sn')
      return openedSidenav !== null && openedSidenav === 'open'
    },

    /**
     * @desc makes the user logout
     */
    doUserLogout() {
      try {
        this.signOutNext()
        // Close materialize sideNav
        $('.open-side-nav').sideNav('hide')

        this.$store.dispatch('user/CLEAR_USER_STATE')
        this.$store.dispatch('booking/CLEAN_RECENT_SEARCH', this.$store.getters.getLocale)
        this.$store.dispatch('bookingConfigure/CLEAR_CONFIGURE_STORAGE')

        if (sessionStorage.getItem('userId')) {
          sessionStorage.removeItem('userId')
        }

        this.shutdownIntercom()

        this._setInsiderEvent({
          insiderEventName: 'logged_out'
        })

        this.$router.push({
          name: this.$routeResolver('routes.home')
        })
      } catch (e) {
        this.$bugsnag.notify(e)
      }
    },
    signOutNext() {
      fetch(window.location.origin + '/api/auth/csrf')
        .then(async (response) => {
          const data = await response.json()
          fetch(window.location.origin + '/api/auth/signout', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'x-auth-return-redirect': 1
            },
            body: new URLSearchParams({
              csrfToken: data.csrfToken,
              callBackUrl: '/api/sso/signout'
            })
          })
        })
    },
    canShowBestPriceRoute () {
      return bestPriceEnum.ALLOWED_LANGUAGES.includes(this.$store.getters.getLocale)
    },

    handleLinkRedirect(e) {
      e.preventDefault()
      const link = e.target.attributes.href.value || e.target.href
      const logged = this.$store.getters['user/getValidLogin']

      if (logged) {
        this.$router.push({ path: link })
      } else {
        this.$router.replace({ query: { redirect: link } })
        this.openAuthModal()
      }
    },

    extractUrl(routeResolver = '') {
      return this.$routeResolver(routeResolver, this.$i18n.locale, true)
    }
  }
}
